import Tab from "../utils/tabs/Tab";
import TabList from "../utils/tabs/TabList";

const ProfileNavigation = ({ isStandard, number, jobUuid, page, job }) => {
  const showAllTabs =
    (!isStandard && !job.job_type.link) || job?.job_type?.show_tabs;

  return (
    <TabList>
      <Tab
        link={`/jobs/${jobUuid}/details`}
        icon="fa fa-file-text-o"
        active={page === "details"}
      >
        Details
      </Tab>
      {showAllTabs && (
        <Tab
          link={`/jobs/${jobUuid}/documents`}
          icon="fa fa-folder-open-o"
          active={page === "documents"}
        >
          Documents
        </Tab>
      )}
      {showAllTabs && job.client && (
        <Tab
          link={`/jobs/${jobUuid}/deliverables`}
          icon="fa fa-gift"
          active={page === "deliverables"}
        >
          Deliverables
        </Tab>
      )}
    </TabList>
  );
};

export default ProfileNavigation;
