import { Link, useParams } from "react-router-dom";
import ReactTable from "../table/ReactTable";
import { Test } from "./testRequestTypes";
import { useMemo, useState } from "react";
import useModal from "../hooks/useModal";
import TestHistoryModal from "./TestHistoryModal";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledTooltip,
} from "reactstrap";
import { Row, Table } from "@tanstack/react-table";
import useApi from "../api/useApi";
import { IUseApi, IUseApiWithData } from "../api/apiTypes";
import swal from "sweetalert";
import errorSwal from "../utils/errorSwal";
import SupervisorModal from "./SupervisorModal";
import _ from "lodash";
import { FiMoreVertical } from "react-icons/fi";
import TestReportButtons from "./TestReportButtons";
import TestAddPurchaseOrderButton from "./TestAddPurchaseOrderButton";
import { TestWorksheet } from "../testWorksheets/testWorksheetTypes";
import { GrUndo } from "react-icons/gr";
import WithdrawReportModal from "./WithdrawReportModal";

const NewTestTable = ({
  tests,
  loading,
  setSelectedTests,
  setTableInstance,
  setTests,
  selectedTests,
  projectID,
  fetchTests,
  testRequestID,
  job,
}: {
  tests: Test[];
  loading: boolean;
  setSelectedTests: (tests: Test[]) => void;
  setTableInstance: (table: Table<any>) => void;
  setTests: (tests: Test[]) => void;
  selectedTests: Test[];
  projectID: number;
  testRequestID: number;
  fetchTests: Function;
  job: {
    branch_manager_id: number;
  };
}) => {
  const { toggle, modal } = useModal();

  const { toggle: toggleSupervisor, modal: supervisorModal } = useModal();

  const [selectedTest, setSelectedTest] = useState<Test>();
  const [supervisorTest, setSupervisorTest] = useState<Test>();
  const [selectedStatus, setSelectedStatus] = useState<string>();

  const { takeAction }: IUseApi = useApi();

  const { toggle: toggleWithdraw, modal: withdrawModal } = useModal();

  const [selectedWorksheet, setSelectedWorksheet] = useState();

  const columns = useMemo(() => {
    return [
      {
        accessorKey: "sample_number_id",
        header: "Sample number",
      },
      {
        accessorKey: "method",
        header: "Method",
        cell: (info: any) => {
          const test = info.row.original;

          if (test.link) {
            return <Link to={test.link}>{test.method}</Link>;
          }

          return info.getValue();
        },
      },
      {
        accessorKey: "report",
        header: "Report",
        cell: (info: any) => {
          const report = info.getValue();

          if (!report) {
            return "-";
          }

          return (
            <Link to={`/deliverables/${report.uuid}/deliverable`}>
              {report.name}
            </Link>
          );
        },
      },
      {
        accessorKey: "status",
        header: "Status",
        style: {
          minWidth: "150px",
        },
        cell: (info: any) => {
          const test = info.row.original;

          return (
            <select
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => {
                const oldTests = tests;

                const status = e.target.value;

                setTests(
                  tests.map((t) =>
                    t.uuid === test.uuid ? { ...t, status } : t,
                  ),
                );

                takeAction("update", `tests/${test.uuid}`, {
                  status,
                }).catch((err) => {
                  const { errors } = err.response.data;

                  setTests(oldTests);

                  if (errors?.not_competent) {
                    return swal({
                      title: "Not Competent",
                      icon: "warning",
                      text: "You are not added as competent for this test and so are unable to change its status. Please talk to your supervisor about being added as competent",
                      buttons: {
                        cancel: {
                          text: "Ok",
                          value: null,
                          closeModal: true,
                          visible: true,
                        },
                      },
                    });
                  }
                  if (errors?.supervisor_required) {
                    setSelectedStatus(status);
                    setSupervisorTest(test);
                    toggleSupervisor();

                    return;
                  }

                  return errorSwal(err);
                });
              }}
              value={test.status}
              className="p-2 rounded-lg border w-100"
            >
              {test.statuses.map((status: { name: string; id: number }) => {
                return <option value={status.id}>{status.name}</option>;
              })}
            </select>
          );
        },
      },
      {
        accessorKey: "purchase-order",
        header: "Purchase Order",
        cell: (info: any) => {
          const test = info.row.original;
          const purchaseOrder = test.purchase_order;

          if (!purchaseOrder) {
            return "--";
          }

          return (
            <Link to={`/purchase-orders/${purchaseOrder?.uuid}/details`}>
              {purchaseOrder?.prefix} - {purchaseOrder?.number}
            </Link>
          );
        },
      },
      {
        accessorKey: "worksheet-id",
        header: "Worksheet",
        cell: (info: any) => {
          const test = info.row.original;

          if (!test.test_request_worksheet) {
            return "-";
          }

          const link =
            test.test_request_worksheet?.document?.external_links?.find(
              (d: any) => d.name === "Teams",
            )?.url;

          return (
            <div className="d-flex space-x-3">
              <a target="_blank" rel="noopener noreferrer" href={link}>
                Worksheet
              </a>
              {/* {"share" in navigator && (
                <TextButton
                  onClick={(e) => {
                    e.stopPropagation();

                    navigator.share({
                      url: link,
                    });
                  }}
                >
                  <BsShare />
                </TextButton>
              )} */}
              {test.test_request_worksheet.signed_at && (
                <>
                  <UncontrolledTooltip target={`tests_${test.uuid}`}>
                    Withdraw Worksheet
                  </UncontrolledTooltip>
                  <button
                    type="button"
                    id={`tests_${test.uuid}`}
                    className="bg-transparent border-0 p-0 "
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedWorksheet(test.test_request_worksheet);
                      toggleWithdraw();
                    }}
                  >
                    <GrUndo />
                  </button>
                  
                </>
              )}
            </div>
          );
        },
      },
      {
        accessorKey: "history",
        header: "History",
        cell: (info: any) => {
          return (
            <Button
              onClick={(e) => {
                e.stopPropagation();
                setSelectedTest(info.row.original);
                toggle();
              }}
              color="link"
            >
              View
            </Button>
          );
        },
      },
    ];
  }, [tests, selectedTest, selectedStatus, selectedWorksheet, withdrawModal]);

  return (
    <>
      <ReactTable
        loading={loading}
        columns={columns}
        data={tests}
        enableMultiSelect
        extraButtons={
          <TestDropdown
            tests={tests}
            setSelectedTests={setSelectedTests}
            setTests={setTests}
            selectedTests={selectedTests}
            projectID={projectID}
            testRequestID={testRequestID}
            fetchTests={fetchTests}
            job={job}
          />
        }
        setSelectedRows={setSelectedTests}
        getTableInstance={setTableInstance}
        onRowClick={(test, e: unknown, row: Row<any>) => {
          setSelectedTests(_.xor(selectedTests, [test]));
          row.getToggleSelectedHandler()(e);
        }}
      />
      <TestHistoryModal
        toggle={() => {
          setSelectedTest(undefined);
          toggle();
        }}
        modal={modal}
        test={selectedTest}
      />
      <SupervisorModal
        toggle={() => {
          setSupervisorTest(undefined);
          setSelectedStatus(undefined);
          toggleSupervisor();
        }}
        modal={supervisorModal}
        test={supervisorTest}
        status={selectedStatus}
        tests={tests}
        setTests={setTests}
      />
      <WithdrawReportModal
        toggle={toggleWithdraw}
        modal={withdrawModal}
        testRequestWorksheet={selectedWorksheet}
        fetchTests={fetchTests}
      />
    </>
  );
};

const TestDropdown = ({
  tests,
  setSelectedTests,
  setTests,
  selectedTests,
  projectID,
  testRequestID,
  fetchTests,
  job,
}: {
  tests: Test[];
  setSelectedTests: (tests: Test[]) => void;
  setTests: (tests: Test[]) => void;
  selectedTests: Test[];
  projectID: number;
  testRequestID: number;
  fetchTests: Function;
  job: {
    branch_manager_id: number;
  };
}) => {
  const { uuid } = useParams<{ uuid: string }>();
  const { toggle, modal } = useModal();

  const {
    data: testRequestDeliverables,
  }: IUseApiWithData<{ name: string; uuid: string }[]> = useApi(
    `/test-requests/${uuid}/deliverables`,
    [],
  );

  const { data: testWorksheets }: IUseApiWithData<TestWorksheet[]> = useApi(
    "test-worksheets",
    [],
  );

  if (selectedTests.length === 0) {
    return null;
  }

  return (
    <Dropdown toggle={() => {}} isOpen={modal}>
      <DropdownToggle onClick={toggle} color="link" className="p-1">
        <FiMoreVertical className="tx-18 text-secondary" />
      </DropdownToggle>
      <DropdownMenu className="z-20">
        <DropdownItem className="px-2" header>
          Report Actions
        </DropdownItem>
        <TestReportButtons
          tests={tests}
          setTests={setTests}
          selectedTests={selectedTests}
          setSelectedTests={setSelectedTests}
          testRequestDeliverables={testRequestDeliverables}
          testWorksheets={testWorksheets}
          toggle={toggle}
          fetchTests={fetchTests}
          job={job}
        />
        <DropdownItem className="px-2" header>
          Purchase Order Actions
        </DropdownItem>
        <TestAddPurchaseOrderButton
          selectedTests={selectedTests}
          setSelectedTests={setSelectedTests}
          projectID={projectID}
          fetchTests={fetchTests}
          testRequestID={testRequestID}
        />
      </DropdownMenu>
    </Dropdown>
  );
};

export default NewTestTable;
