import { Field } from "redux-form";
import FormModal from "../../utils/FormModal";
import renderField from "../../utils/renderField";
import required from "../../utils/required";
import useModal from "../../hooks/useModal";
import { Button } from "reactstrap";
import useApi from "../../api/useApi";
import fileDownload from "js-file-download";
import dayjs from "dayjs";
import errorSwal from "../../utils/errorSwal";
import { toast } from "react-toastify";

const SampleDumpModal = ({
  filters,
}: {
  filters: any[];
}) => {
  const { takeAction } = useApi();
  const { modal, toggle } = useModal();

  const generateDump = (data: any) => {
    if (parseInt(data.from) > parseInt(data.to)) {
      return toast.warning(
        "The end sample number must be greater than or equal to the start sample number.",
      );
    }

    const baseUrl = 'samples/dump';
    const params = new URLSearchParams();
    params.append('filter[number_between]', `${data.from},${data.to}`);

    params.append('sort', 'number');

    for (const key in filters) {
      if (Array.isArray(filters[key])) {
        params.append(`filter[${key.replace('.', '_')}]`, filters[key].join(','));
      } else {
        params.append(`filter[${key.replace('.', '_')}]`, filters[key]);
      }
    }

    const url = `${baseUrl}?${params.toString()}`;

    return takeAction(
      "store",
      url,
    )
      .then((res: any) => {
        fileDownload(
          res.data,
          `Sample-Export ${dayjs().format("DD-MM-YYYY, LT")}.csv`,
        );
        toast.success("Downloading File!");
        toggle();
      })
      .catch(errorSwal);
  };

  return (
    <>
      <Button
        onClick={toggle}
        outline
        size="sm"
        color="primary"
        className="ms-3 mt-1"
      >
        Download Samples
      </Button>
      <FormModal
        title="Choose Sample Range"
        onSubmit={generateDump}
        modal={modal}
        toggle={toggle}
        form="SampleDump"
      >
        <div className="col-6 form-group">
          <Field
            component={renderField}
            name="from"
            label="Start Sample Number"
            validate={required}
            required
            type="number"
          />
        </div>
        <div className="col-6 form-group">
          <Field
            component={renderField}
            name="to"
            label="End Sample Number"
            validate={required}
            required
            type="number"
          />
        </div>
      </FormModal>
    </>
  );
};

export default SampleDumpModal;
