import { useState } from "react";
import JobTypeModal from "../jobs/JobTypeModal.jsx";
import ProjectProfileButtons from "../jobs/ProjectProfileButtons";
import RecurringJobs from "../recurringJobs";
import ProjectJobCalendar from "./ProjectJobCalendar";
import ProjectJobs from "./ProjectJobs";
import { Link, useHistory, useParams } from "react-router-dom";
import ProjectJobFolders from "../jobs/ProjectJobFolders";

const ProjectJobsTabsList = (props) => {
  const [modal, setModal] = useState(false);
  const [selectedJob, setSelectedJob] = useState("");

  const { number } = useParams();

  const { project } = props;

  const toggle = () => setModal(!modal);

  return (
    <>
      <div className="mg-b-0">
        <ProjectProfileButtons
          project={project}
          toggle={toggle}
          selectedJob={selectedJob}
        />
        <JobTypeModal
          {...props}
          project={project}
          projectUuid={number}
          modal={modal}
          toggle={toggle}
        />
      </div>

      <JobTabs
        selectedJob={selectedJob}
        setSelectedJob={setSelectedJob}
        project={project}
      />
    </>
  );
};

const JobTabs = ({ setSelectedJob, selectedJob, project }) => {
  const { number, folderUuid: jobPage } = useParams();

  return (
    <>
      <div className="nav-statistics-wrapper">
        <nav style={{ overflowX: "auto" }} className="nav flex-nowrap">
          <Link
            to={`/projects/${number}/jobs/list`}
            className={`nav-link mb-3 ${jobPage === "list" ? "active" : ""}`}
          >
            Jobs
          </Link>
          <Link
            to={`/projects/${number}/jobs/recurring-jobs`}
            className={`nav-link mb-3 ${
              jobPage === "recurring-jobs" ? "active" : ""
            }`}
          >
            Recurring Jobs
          </Link>
          <Link
            to={`/projects/${number}/jobs/folders`}
            className={`nav-link mb-3 ${jobPage === "folders" ? "active" : ""}`}
          >
            Job Folders
          </Link>
          <Link
            to={`/projects/${number}/jobs/calendar`}
            className={`nav-link mb-3 ${
              jobPage === "calendar" ? "active" : ""
            }`}
          >
            Calendar
          </Link>
        </nav>
      </div>
      <JobTabPages
        selectedJob={selectedJob}
        setSelectedJob={setSelectedJob}
        project={project}
      />
    </>
  );
};

const JobTabPages = ({ selectedJob, setSelectedJob, project }) => {
  const { folderUuid: jobPage } = useParams();
  const history = useHistory();

  switch (jobPage) {
    case "list":
      return (
        <ProjectJobs
          selectedJob={selectedJob}
          setSelectedJob={setSelectedJob}
        />
      );
    case "recurring-jobs":
      return <RecurringJobs project={project} />;
    case "calendar":
      return <ProjectJobCalendar project={project} />;
    case "folders":
      return <ProjectJobFolders project={project} />;
    default:
      return history.push(`/jobs/${jobPage}/details`);
  }
};

export default ProjectJobsTabsList;
